import React, { useState }  from 'react'
import Layout from '../components/layout' 
import { Container, Row, Col } from 'reactstrap'
import banner from '../images/contact-bg.jpg'
import contactImage from '../images/contact-image.png' 
import { seoData } from '../data/seo-data'
import { ContactForm } from '../components/contact-form'
// const { compose, withProps  } = require("recompose");

/*
const styles: any = [
    {
        featureType: 'poi.business',
        stylers: [{ visibility: 'off' }]
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }]
    }
];
//-37.8378803,144.9933658
const loc = [{
    lat: -37.8378803,
    lng: 144.9933658
}]
const MapWithAMarkerClusterer = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDKI7MIqj064dRWv0ynvMujy078D5yehzU&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `450px` }} />,
        containerElement: <div style={{ height: `450px` }} />,
        mapElement: <div style={{ height: `450px` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props: any) =>
    <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: -37.8378803, lng: 144.9933658 }}
        defaultOptions={{ style: styles }}
    >
        <MarkerClusterer
            onClick={props.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            gridSize={60}
        >
            {props.markers.map((marker: any) => (
                <Marker
                    key={marker.photo_id}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    icon={{ url: maps }}
                />
            ))}
        </MarkerClusterer>
    </GoogleMap>
);
<section style={{ minHeight: 450 }}>
                <MapWithAMarkerClusterer markers={loc} />
            </section>
*/

const Contact = () => { 
 
    const [uid, setUid] = useState("")
    return (
        <Layout
            seo={{ title: seoData.contactUs.title, description: seoData.contactUs.description, keyword: '' }}
            banner={<FreeConsulTationBanner />}
            navStayle="SIMPLE"
            footerType="HOMEPAGE"
            setUid={(uid: string) => setUid(uid)}
        >
             
            
             <ContactForm uid={uid}/>
        </Layout>
    )
}

const FreeConsulTationBanner = () => {
    return (
        <>
            <div style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundPosition: 'top center', paddingTop: 110 }}>
                <Container>
                    <Row style={{ textAlign: "center" }}>
                        <Col md={4} >
                            <div style={{ marginTop: '50%', background: 'rgba(1, 107, 146, 0.75)', padding: '10px 0' }}>
                                <h3 className="header-font" style={{ color: '#fff', fontSize: '5em', marginBottom: 0, width: 'auto' }}>Contact Us </h3>
                            </div>
                        </Col>
                        <Col md={8}>
                            <img src={contactImage} alt="" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{ backgroundColor: 'rgb(1, 107, 146)', minHeight: 50 }}>

            </div>
        </>
    )
}

export default Contact